<template>
  <div class="products">
    <div class="overlay" :class="{ active: filtered }" @click="$emit('filtered', false)" />
    <div class="product-header" :class="{ active: filtered }">
      <Filtrele :filter="filter" @filtered="handlerTemizle" @handlerFilter="handlerFilter" />
    </div>
    <div class="product-body">
      <b-alert v-if="!rows.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>
      <div class="p-2" v-else>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :pagination-options="{
            enabled: true,
            perPage: 100,
            mode: 'pages',
          }"
          @on-selected-rows-change="selectionChanged"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionText: 'Satır Secildi.',
            clearSelectionText: 'Temizle',
          }"
          :expandOptions="{ enabled: true, selfManaged: true }"
          :expandedRows="[1, 4, 12, 54]"
          styleClass="vgt-table striped bordered"
        >
          <div slot="selected-row-actions">
            <b-button squared variant="danger" v-b-tooltip.hover="'Toplu Sil'" @click="handlerTopluSil">
              <i class="fad fa-trash" />
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'urun_tipi'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                <b-badge variant="" class="d-block mt-1 rounded-0">
                  {{ urunTipi(props.row.urun_tipi) }}
                </b-badge>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'islem_tarihi'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                <b-badge variant="" class="d-block rounded-0 font-weight-light" style="font-size: 12px">
                  {{ props.row.islem_tarihi | momentFull }}</b-badge
                >
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'urun_adi'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                <b-badge class="d-block rounded-0 font-weight-light text-left" style="font-size: 12px">
                  Rez No :{{ props.row.k_no }}
                </b-badge>
                <template v-if="props.row.urun_tipi == 'villa' || props.row.urun_tipi == 'yat'">
                  <b-badge
                    v-for="(urun, index) in props.row.urunler"
                    :key="index"
                    variant="primary"
                    class="d-block mt-1 rounded-0 text-left"
                    style="font-size: 12px"
                  >
                    {{ urun.baslik['tr'] }}
                  </b-badge>
                </template>
                <template v-else>
                  <template v-if="props.row.sepet_urunler.length > 1">
                    <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 13px">
                      +{{ props.row.sepet_urunler.length }} Ürün İçeriyor
                    </b-badge>
                  </template>
                  <template v-else>
                    <b-badge
                      v-for="(urun, index) in props.row.sepet_urunler"
                      :key="index"
                      variant="primary"
                      class="d-block mt-1 rounded-0 text-left rounded-0 font-weight-light"
                      style="font-size: 12px"
                      v-b-tooltip.hover.top="urun.baslik['tr']"
                    >
                      {{ urun.baslik['tr'] }}
                    </b-badge>
                  </template>
                </template>
                <template v-if="props.row.ac_k_no">
                  <b-badge variant="info" class="d-block mt-1 rounded-0 text-left" style="font-size: 12px">
                    <span v-if="props.row.ana_ac_k_no" style="font-size: 12px"> ( {{ props.row.ana_acente_firma_adi }} ) - </span>
                    {{ props.row.acente_firma_adi }} - {{ props.row.acente_temsilci }}
                  </b-badge>
                </template>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'fiyat'">
              <template v-if="props.row.sabit_kur == props.row.secilen_kur">
                <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
                  Toplam Tutar :
                  {{ props.row.rezervasyon_toplam.genel_toplam | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>
                <b-badge
                  variant="info"
                  class="rounded-0 font-weight-light"
                  style="font-size: 12px"
                  v-if="props.row.rezervasyon_toplam.on_odeme_toplam"
                >
                  Ön Ödeme :
                  {{ props.row.rezervasyon_toplam.on_odeme_toplam | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>
                <b-badge variant="info" class="rounded-0 font-weight-light" style="font-size: 12px">
                  Tahsilat : {{ props.row.tahsilat | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>

                <b-badge
                  :variant="kalanBakiye(props.row.rezervasyon_toplam.genel_toplam, props.row.tahsilat) > 0 ? 'danger' : 'success'"
                  class="rounded-0 font-weight-light"
                  style="font-size: 12px"
                >
                  Bakiye :
                  {{ kalanBakiye(props.row.rezervasyon_toplam.genel_toplam, props.row.tahsilat) | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>
              </template>
              <template v-else>
                <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
                  Toplam Tutar :
                  {{ props.row.kur_rezervasyon_toplam.ara_toplam | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>
                <b-badge
                  variant="info"
                  class="rounded-0 font-weight-light"
                  style="font-size: 12px"
                  v-if="props.row.kur_rezervasyon_toplam.on_odeme_toplam"
                >
                  Ön Ödeme :
                  {{ props.row.kur_rezervasyon_toplam.on_odeme_toplam | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>
                <b-badge variant="info" class="rounded-0 font-weight-light" style="font-size: 12px">
                  Tahsilat : {{ props.row.tahsilat | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>

                <b-badge
                  :variant="kalanBakiye(props.row.kur_rezervasyon_toplam.genel_toplam, props.row.tahsilat) > 0 ? 'danger' : 'success'"
                  class="rounded-0 font-weight-light"
                  style="font-size: 12px"
                >
                  Bakiye :
                  {{ kalanBakiye(props.row.kur_rezervasyon_toplam.genel_toplam, props.row.tahsilat) | cur }}
                  {{ props.row.secilen_kur }}
                </b-badge>
              </template>
            </span>
            <span v-else-if="props.column.field === 'odeme'">
              <b-badge variant="success" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{
                  props.row.odeme_yontemi == 'kredi_karti'
                    ? 'Kredi Kartı'
                    : props.row.odeme_yontemi == 'havale_eft'
                    ? 'Havale'
                    : 'Kapıda Ödeme'
                }}
              </b-badge>
              <br />
              <b-badge
                :variant="props.row.odeme_tipi == 'on_odeme' ? 'warning' : 'info'"
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
              >
                {{ props.row.odeme_tipi == 'on_odeme' ? 'Ön Ödemeli' : 'Tamamı' }}
              </b-badge>
              <b-badge
                :variant="props.row.tahsilat_durum == 1 ? 'info' : props.row.tahsilat_durum == 2 ? 'success' : 'danger'"
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
              >
                {{ props.row.tahsilat_durum | tahsilatDurum }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <b-badge class="rounded-0 font-weight-light" style="font-size: 12px" :style="{ background: props.row.rezervasyon_drm_renk }">
                {{ props.row.rezervasyon_drm['tr'].baslik }}
              </b-badge>
              <b-badge
                :variant="
                  props.row.rezervasyon_kapama == 'kapandi' ? 'danger' : props.row.rezervasyon_kapama == 'aktif' ? 'success' : 'danger'
                "
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
              >
                {{ props.row.rezervasyon_kapama | rezKapama }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret menu-class="w-100">
                <template #button-content>
                  <i class="fad fa-ellipsis-v-alt"></i>
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'rezervasyon-detay',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <i class="fad fa-eye" /> Detay</b-dropdown-item
                >
                <b-dropdown-item
                  :to="{
                    name: 'rezervasyon-guncelle',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <i class="fad fa-edit" /> Güncelle</b-dropdown-item
                >
                <b-dropdown-item
                  :to="{
                    name: 'rezervasyon-fisi',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <i class="fad fa-edit" /> Yazdır</b-dropdown-item
                >
                <b-dropdown-item v-if="props.row.rezervasyon_kapama == 'aktif'" @click="handlerRemove(props.row.k_no)">
                  <i class="fad fa-trash" /> Sil
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="expanded-row" slot-scope="props">
            <div>
              {{ props.row }}
            </div>
          </template>
          <template slot="pagination-bottom">
            <Pagination
              :urunler="rows"
              :count="count"
              @handlerPageSizeChange="handlerPageSizeChange($event)"
              @handlerPageChange="handlerPageChange($event)"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';

import Filtrele from './component/listele/Filtrele.vue';
import Pagination from './component/listele/Pagination.vue';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    Filtrele,
    Pagination,
  },
  props: {
    filtered: [Boolean],
    itemView: [String],
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.multiSelectRow = ref([]);
    expo.pageLength = ref(20);
    expo.perPageOptions = ref([10, 20, 50, 100]);
    expo.columns = ref([
      {
        label: 'İşlem Tarihi',
        field: 'islem_tarihi',
        width: '11%',
      },
      {
        label: 'Ürün Adı',
        field: 'urun_adi',
      },
      {
        label: 'Fiyat',
        field: 'fiyat',
        width: '15%',
        tdClass: 'text-right',
      },
      {
        label: 'Ödeme',
        field: 'odeme',
        width: '11%',
        tdClass: 'text-right',
      },
      {
        label: 'Statü',
        field: 'statu',
        width: '18%',
        tdClass: 'text-right',
      },
      {
        field: 'action',
        width: '3%',
      },
    ]);

    expo.rows = ref([]);
    expo.count = ref(0);
    expo.filter = ref({
      urun_tipi: null,
      islem_tarihi: null,
      tahsilat_durum: null,
      rezervasyon_durum: null,
      rezervasyon_kapama: null,
    });

    expo.selectionChanged = (event) => {
      expo.multiSelectRow.value = event.selectedRows;
    };

    expo.urunTipi = computed(() => {
      const urun = {
        villa: 'Villa',
        yat: 'Yat',
        tur: 'Tur',
        arac_kira: 'Araç Kira',
        transfer: 'Transfer',
      };

      return (val) => urun[val];
    });

    expo.tahsilEdilen = computed(() => {
      return (val) => {
        let toplam = 0;
        const tahsilat = val;
        if (tahsilat) {
          tahsilat.forEach((el) => {
            if (el.gc_kodu == 'G') {
              toplam += parseFloat(el.tutar);
            } else {
              toplam -= Math.abs(parseFloat(el.tutar));
            }
          });
        }
        return toplam;
      };
    });

    expo.kalanBakiye = computed(() => {
      return (genel_toplam, tahsil) => {
        let toplam = 0;
        toplam = genel_toplam - tahsil;
        return Math.floor(toplam);
      };
    });

    expo.handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('pageTitle', 'Rezervasyonlar');

      route.value.query.page = route.value.query.page ? parseInt(route.value.query.page) : 1;
      route.value.query.page_size = route.value.query.page_size ? parseInt(route.value.query.page_size) : 20;
      route.value.query.satis_yontemi = 'direk';

      await store.dispatch('rezervasyonListele', route.value.query).then((res) => {
        expo.rows.value = res.data.data;
        expo.count.value = res.data.count;
        context.emit('total', `${expo.count.value} kayıttan ${expo.rows.value.length} `);
        context.emit('show', false);
        // context.emit('filtered', false);
      });
    };

    expo.handlerFilter = () => {
      let query = {};

      if (expo.filter.value.urun_tipi) {
        query = {
          ...route.value.query,
          urun_tipi: expo.filter.value.urun_tipi,
        };
      } else {
        delete query.urun_tipi;
      }
      if (expo.filter.value.islem_tarihi) {
        query = {
          ...route.value.query,
          islem_tarihi: expo.filter.value.islem_tarihi,
        };
      } else {
        delete query.islem_tarihi;
      }

      if (expo.filter.value.tahsilat_durum) {
        query = {
          ...route.value.query,
          tahsilat_durum: parseInt(expo.filter.value.tahsilat_durum),
        };
      } else {
        delete query.tahsilat_durum;
      }

      if (expo.filter.value.rezervasyon_durum) {
        query = {
          ...route.value.query,
          rezervasyon_durum: expo.filter.value.rezervasyon_durum,
        };
      } else {
        delete query.rezervasyon_durum;
      }

      if (expo.filter.value.rezervasyon_kapama) {
        query = {
          ...route.value.query,
          rezervasyon_kapama: expo.filter.value.rezervasyon_kapama,
        };
      } else {
        delete query.rezervasyon_kapama;
      }

      router.push({
        query: { ...query },
      });
    };

    expo.handlerTemizle = () => {
      router.push({
        query: {},
      });

      expo.filter.value = {
        urun_tipi: null,
        islem_tarihi: null,
        tahsilat_durum: null,
        rezervasyon_durum: null,
        rezervasyon_kapama: null,
      };

      context.emit('filtered', false);
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('rezervasyonSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerTopluSil = () => {
      let kno = [];
      expo.multiSelectRow.value.forEach((element) => {
        kno.push(element.k_no);
      });

      Swal.fire({
        title: 'Uyarı',
        text: `Seçilen ${kno.length} Kayit Silinecektir. Eminmisiniz ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          context.emit('show', true);
          store.dispatch('rezervasyonTopluSil', { k_no: kno }).then((res) => {
            if (res.data.success == true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
              context.emit('show', false);
            }
          });
        }
      });
    };

    watch(
      route,
      (val) => {
        if (val.hash != '#' && val.name == 'rezervasyon-listele') {
          expo.filter.value = {
            urun_tipi: route.value.query.urun_tipi ? route.value.query.urun_tipi : null,
            islem_tarihi: route.value.query.islem_tarihi ? route.value.query.islem_tarihi : null,
            tahsilat_durum: route.value.query.tahsilat_durum ? route.value.query.tahsilat_durum : null,
            rezervasyon_durum: route.value.query.rezervasyon_durum ? route.value.query.rezervasyon_durum : null,
            rezervasyon_kapama: route.value.query.rezervasyon_kapama ? route.value.query.rezervasyon_kapama : null,
          };
          expo.handlerFetchAllData();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
