<template>
  <div class="product-search">
    <b-form @submit.stop.prevent="$emit('handlerFilter', true)">
      <b-row>
        <b-col cols="12" md="4">
          <v-select
            v-model="filter.urun_tipi"
            :options="tipler"
            :reduce="(durum) => durum.id"
            label="title"
            :clearable="false"
            placeholder="Rezervasyon Tipi..."
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-col>
        <b-col cols="12" md="4">
          <flat-pickr
            class="form-control form-control-lg rounded-0 border mb-4 flat-input bg-white"
            v-model="date"
            :config="config"
            :events="config.events"
            placeholder="İşlem Tarihi"
          />
        </b-col>
        <b-col cols="12" md="4">
          <v-select
            v-model="filter.tahsilat_durum"
            :options="tahsilatDurumlari"
            :reduce="(tah) => tah.id"
            placeholder="Tahsilat Durumu"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block select-size-lg"
            style="width: 100%"
          />
        </b-col>
        <b-col cols="12" md="4" v-if="rezervasyonDurumlari">
          <v-select
            v-model="filter.rezervasyon_durum"
            :options="rezervasyonDurumlari"
            :reduce="(rez) => rez.k_no"
            label="baslik"
            :clearable="false"
            placeholder="Rezervasyon Durumu"
            class="invoice-filter-select d-block select-size-lg"
            style="width: 100%"
          >
            <template slot="selected-option" slot-scope="option">
              {{ option.icerik[defaultDil].baslik }}
            </template>
            <template slot="option" slot-scope="option">
              {{ option.icerik[defaultDil].baslik }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="4">
          <v-select
            v-model="filter.rezervasyon_kapama"
            :options="rezervasyonKapamaDurum"
            :reduce="(kapama) => kapama.value"
            placeholder="Rezervasyon Kapama Durum"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block select-size-lg"
            style="width: 100%"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-button type="submit" block variant="info" size="lg" class="rounded-0">
            <i class="fad fa-filter"></i> FİLTRE UYGULA
          </b-button>
        </b-col>
        <b-col cols="12" md="6">
          <b-button block variant="danger" size="lg" class="rounded-0" @click="$emit('filtered', false)">
            <i class="fad fa-window-close"></i> TEMİZLE
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { defineComponent, computed, onMounted, ref } from '@vue/composition-api';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
export default defineComponent({
  components: { vSelect, Treeselect, flatPickr },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.rezervasyonDurumlari = ref(null);
    expo.tahsilatDurumlari = ref([
      {
        id: '0',
        title: 'Tahsil Edilmedi',
      },
      {
        id: '1',
        title: 'Ön Ödeme Tahsil Edildi',
      },
      {
        id: '2',
        title: 'Tamamı Tahsil Edildi',
      },
      {
        id: '3',
        title: 'Beklemede',
      },
    ]);

    expo.rezervasyonKapamaDurum = ref([
      {
        value: 'aktif',
        title: 'Rezervasyon Aktif',
      },
      {
        value: 'kapandi',
        title: 'Rezervasyon Kapandı',
      },
      {
        value: 'iptal',
        title: 'Rezervasyon İptal Edildi',
      },
    ]);

    expo.date = ref(null);
    expo.config = ref({
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      showMonths: 1,
      mode: 'range',
      minDate: 'today',
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      altInput: false,
      disable: [],
      enable: [],
      locale: Turkish, // locale for this instance only
    });

    expo.tipler = ref([
      { id: 'villa', title: 'Villa' },
      { id: 'yat', title: 'Yat' },
      { id: 'tur', title: 'Tur' },
      { id: 'arac_kira', title: 'Araç Kiralama' },
      { id: 'transfer', title: 'Transfer' },
    ]);

    onMounted(async () => {
      await store.dispatch('rezervasyonDurumListele', { statu: true });
      expo.rezervasyonDurumlari.value = store.getters.getRezervasyonDurumlari;
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
